<template>
    <div>
        <app-layout>
	        <template v-slot:header>
	            <Header :title="$t('prep_schedule_draft_title')"
	                    :isColumns="true"
	                    :isNewButton="checkPermission('prepscheduledraft_store')"
	                    :isImportButton="checkPermission('prepscheduledraft_import')"
	                    @import-button-click="importData"
	                    @new-button-click="$refs.defineModal.$refs.commonModal.show()"
	                    @filter-div-status="datatable.filterStatus=$event">
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'">
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('prep_schedule_draft_title')"
		                      :isColumns="true"
		                      :isNewButton="checkPermission('prepscheduledraft_store')"
		                      :isImportButton="checkPermission('prepscheduledraft_import')"
		                      @import-button-click="importData"
		                      @new-button-click="$refs.defineModal.$refs.commonModal.show()"
		                      @filter-div-status="datatable.filterStatus=$event">
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'">
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </HeaderMobile>
	        </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox v-model="datatable.queryParams.filter.academic_year"></academic-years-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('module')">
                            <module-selectbox
                                :academic_year="datatable.queryParams.filter.academic_year"
                                v-model="datatable.queryParams.filter.module">
                            </module-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('level')">
                            <parameter-selectbox
                                code="prep_levels"
                                v-model="datatable.queryParams.filter.level">
                            </parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('class')">
                            <prep-classes-selectbox
                                :academic_year="datatable.queryParams.filter.academic_year"
                                :module="datatable.queryParams.filter.module"
                                :level="datatable.queryParams.filter.level"
                                v-model="datatable.queryParams.filter.class_id">
                            </prep-classes-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('day')">
                            <days-selectbox v-model="datatable.queryParams.filter.day">
                            </days-selectbox>
                        </b-form-group>
                    </b-col>

                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal size="xl" ref="defineModal">
                <template v-slot:CommonModalTitle>
                    <div>{{$t('new_draft')}}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <define-form @hide="closeDefineModal"></define-form>
                    </div>
                </template>
            </CommonModal>
            <CommonModal size="xl" ref="editModal">
                <template v-slot:CommonModalTitle>
                    <div>{{$t('update_draft')}}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <update-form :id="selectedId" @hide="closeEditModal"></update-form>
                    </div>
                </template>
            </CommonModal>
            <CommonModal size="lg" ref="importModal">
                <template v-slot:CommonModalTitle>
                    <div>{{$t('import_draft')}}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <import-form @hide="closeImportModal"></import-form>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import Datatable from "@/components/datatable/Datatable";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import qs from "qs";
import CommonModal from "@/components/elements/CommonModal";
import UpdateForm from "./updateForm";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import DefineForm from "./defineForm";
import PrepScheduleDraftsService from "@/services/PrepScheduleDraftsService";
import ModuleSelectbox from "@/components/interactive-fields/ModuleSelectbox";
import PrepClassesSelectbox from "@/components/interactive-fields/PrepClassesSelectbox";
import DaysSelectbox from "@/components/interactive-fields/DaysSelectbox";
import ImportForm from "./importForm";

export default {
    components: {
        AppLayout,
        Header,
	    HeaderMobile,
	    
        Datatable,
        DatatableFilter,
        CommonModal,
        UpdateForm,
        AcademicYearsSelectbox,
        ParameterSelectbox,
        DefineForm,
        ModuleSelectbox,
        PrepClassesSelectbox,
        DaysSelectbox,
        ImportForm
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons:[
                            {
                                text: this.$t('edit'),
                                class: 'ri-pencil-fill',
                                permission: "prepscheduledraft_update",
                                callback:(row)=>{
                                    this.selectedId = row.id
                                    this.$refs.editModal.$refs.commonModal.show()
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-7-line',
                                permission: "prepscheduledraft_delete",
                                callback:(row)=>{
                                    this.deleteItem(row)
                                }
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase('id'),
                        field: 'id',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('academic_year'),
                        field: 'academic_year',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('module'),
                        field: 'module',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('level'),
                        field: 'level',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('class'),
                        field: 'class',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('type'),
                        field: 'type',
                        hidden: true,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('day'),
                        field: 'day_name',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('start_time'),
                        field: 'start_time',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('end_time'),
                        field: 'end_time',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('instructor'),
                        field: 'instructor',
                        hidden: false,
                        sortable: false
                    }

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
            selectedId: null
        }
    },
    metaInfo() {
        return {
            title: this.$t("prep_schedule_draft_title")
        }
    },
    methods: {
        filter() {
            this.getRows();
        },

        filterClear() {
            this.filterSet()
            this.getRows();
        },

        filterSet(){
            this.datatable.queryParams.filter = {
                id: null
            };
        },

        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },

        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },

        changeColumn(key){
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden==true ? false:true;
        },

        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },

        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return PrepScheduleDraftsService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                }).finally(() => {
                    this.datatable.isLoading = false;
                });
        },

        deleteItem(row){
            this.$swal({
                title: this.$t('attention'),
                text: this.$t('prep_schedule_draft_delete_confirm_text'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            }).then(response => {
                if(response.isConfirmed == true) {
                    PrepScheduleDraftsService.remove(row.id).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.getRows()
                    }).catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    })
                }
            })
        },

        importData(){
            this.$refs.importModal.$refs.commonModal.show()
        },

        closeEditModal(){
            this.$refs.editModal.$refs.commonModal.hide()
            this.getRows()
        },

        closeDefineModal(){
            this.$refs.defineModal.$refs.commonModal.hide()
            this.getRows()
        },

        closeImportModal(){
            this.$refs.importModal.$refs.commonModal.hide()
            this.getRows()
        }
    },

    created() {
        this.filterSet()
    },

    watch: {}
};
</script>
