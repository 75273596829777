<template>
    <div>
        <div class="row data-form">
            <div class="col-12">
                <div class="mb-4">
                    <h6 class="text-uppercase mb-0">{{$t('draft_info')}}</h6>
                </div>
            </div>
            <div class="col-12 col-md-12">
                <ValidationObserver ref="form">
                    <div class="row">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="academic_year" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('academic_year')">
                                    <academic-years-selectbox
                                        :validate-error="errors[0]"
                                        v-model="defineForm.academic_year">
                                    </academic-years-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="module" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('module')">
                                    <module-selectbox
                                        :academic_year="defineForm.academic_year"
                                        :validate-error="errors[0]"
                                        v-model="defineForm.module">
                                    </module-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="level" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('level')">
                                    <parameter-selectbox
                                        code="prep_levels"
                                        :validate-error="errors[0]"
                                        v-model="defineForm.level">
                                    </parameter-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="class_id" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('class')">
                                    <prep-classes-selectbox
                                        :academic_year="defineForm.academic_year"
                                        :module="defineForm.module"
                                        :level="defineForm.level"
                                        :validate-error="errors[0]"
                                        v-model="defineForm.class_id">
                                    </prep-classes-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="instructor_id" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('instructor')">
                                    <prep-instructors-selectbox
                                        :validate-error="errors[0]"
                                        v-model="defineForm.instructor_id">
                                    </prep-instructors-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('type')">
                                    <b-form-select
                                        v-model="defineForm.type"
                                        :options="typeOptions"></b-form-select>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="day" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('day')">
                                    <days-selectbox
                                        :validate-error="errors[0]"
                                        v-model="defineForm.day">
                                    </days-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="start_time" rules="required" v-slot="{valid, errors}">
                                <b-form-group>
                                    <label>{{ $t('start_time') }}</label>
                                    <b-form-input
                                        type="time"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        v-model="defineForm.start_time"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="end_time" rules="required" v-slot="{valid, errors}">
                                <b-form-group>
                                    <label>{{ $t('end_time') }}</label>
                                    <b-form-input
                                        type="time"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        v-model="defineForm.end_time"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>


                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <b-form-group>
                                <b-button variant="primary" @click="sendForm">{{$t('save')}}</b-button>
                            </b-form-group>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import ModuleSelectbox from "@/components/interactive-fields/ModuleSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import PrepClassesSelectbox from "@/components/interactive-fields/PrepClassesSelectbox";
import PrepInstructorsSelectbox from "@/components/interactive-fields/PrepInstructorsSelectbox";
import DaysSelectbox from "@/components/interactive-fields/DaysSelectbox";
import PrepScheduleDraftsService from "@/services/PrepScheduleDraftsService";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        AcademicYearsSelectbox,
        ModuleSelectbox,
        ParameterSelectbox,
        PrepClassesSelectbox,
        PrepInstructorsSelectbox,
        DaysSelectbox
    },

    data() {
        return {
            defineForm: {},

            typeOptions: [
                {value: null, text: this.$t('select') },
                {value: "in_class", text: this.$t('in_class') },
                {value: "online", text: "Online" },
            ]
        }
    },
    metaInfo() {
        return {
            title: this.$t("prep_schedule_draft_title")
        }
    },
    methods: {

        async sendForm() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                let formData = {
                    ...this.defineForm
                }

                this.$swal({
                    title: this.$t('attention'),
                    text: this.$t('prep_schedule_draft_store_confirm_text'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                }).then(response => {

                    PrepScheduleDraftsService.store(formData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('hide')
                        this.defineForm = {}
                        this.$refs.form.reset();
                    }).catch(e => {
                        this.showErrors(e);
                    })
                })
            }
        }
    },

    created() {

    },
};
</script>
