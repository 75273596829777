<template>
    <div>
        <div class="row data-form">
            <div class="col-12">
                <div class="mb-4">
                    <h6 class="text-uppercase mb-0">{{$t('draft_info')}}</h6>
                </div>
            </div>
            <div class="col-12 col-md-12">
                <ValidationObserver ref="updateForm">
                    <div class="row">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="academicYear" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('academic_year')">
                                    <academic-years-selectbox
                                        :validate-error="errors[0]"
                                        v-model="defineForm.academicYear">
                                    </academic-years-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="module" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('module')">
                                    <module-selectbox
                                        :academic_year="defineForm.academicYear"
                                        :validate-error="errors[0]"
                                        v-model="defineForm.module">
                                    </module-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="level" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('level')">
                                    <parameter-selectbox
                                        code="prep_levels"
                                        :validate-error="errors[0]"
                                        v-model="defineForm.level">
                                    </parameter-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="class" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('class')">
                                    <prep-classes-selectbox
                                        :academic_year="defineForm.academicYear"
                                        :module="defineForm.module"
                                        :level="defineForm.level"
                                        :validate-error="errors[0]"
                                        v-model="defineForm.class">
                                    </prep-classes-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="instructor" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('instructor')">
                                    <prep-instructors-selectbox
                                        :validate-error="errors[0]"
                                        v-model="defineForm.instructor">
                                    </prep-instructors-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('type')">
                                    <multi-selectbox
                                        :multiple="false"
                                        v-model="defineForm.type"
                                        :options="typeOptions"></multi-selectbox>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="day" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('day')">
                                    <days-selectbox
                                        :validate-error="errors[0]"
                                        v-model="defineForm.day">
                                    </days-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="startTime" rules="required" v-slot="{valid, errors}">
                                <b-form-group>
                                    <label>{{ $t('start_time') }}</label>
                                    <b-form-input
                                        v-mask="'##:##'"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        v-model="defineForm.startTime"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="endTime" rules="required" v-slot="{valid, errors}">
                                <b-form-group>
                                    <label>{{ $t('end_time') }}</label>
                                    <b-form-input
                                        v-mask="'##:##'"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        v-model="defineForm.endTime"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <b-form-group>
                                <b-button variant="primary" @click="sendForm">{{$t('save')}}</b-button>
                            </b-form-group>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
    import {ValidationObserver, ValidationProvider} from 'vee-validate';
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import ModuleSelectbox from '@/components/interactive-fields/ModuleSelectbox';
    import AcademicYearsSelectbox from '@/components/interactive-fields/AcademicYearsSelectbox';
    import PrepClassesSelectbox from '@/components/interactive-fields/PrepClassesSelectbox';
    import PrepInstructorsSelectbox from '@/components/interactive-fields/PrepInstructorsSelectbox';
    import DaysSelectbox from '@/components/interactive-fields/DaysSelectbox';
    import PrepScheduleDraftsService from '@/services/PrepScheduleDraftsService';
    import MultiSelectbox from '@/components/interactive-fields/MultiSelectbox';

    export default {
    components: {
        MultiSelectbox,
        ValidationProvider,
        ValidationObserver,
        ParameterSelectbox,
        ModuleSelectbox,
        AcademicYearsSelectbox,
        PrepClassesSelectbox,
        PrepInstructorsSelectbox,
        DaysSelectbox


    },
    props: {
        id: {
            default: null,
            type: Number
        }
    },
    data() {
        return {
            defineForm: {
                academicYear: null,
                module: null,
                level: null,
                class: null,
                instructor: null,
                type: null,
                day: null,
                startTime: null,
                endTime: null
            },

            typeOptions: [
                {value: null, text: this.$t('select') },
                {value: "in_class", text: this.$t('in_class') },
                {value: "online", text: "Online" },
            ]
        }
    },
    metaInfo() {
        return {
            title: this.$t("prep_schedule_draft_title")
        }
    },
    methods: {
        getFormData() {
            PrepScheduleDraftsService.show(this.id)
                                     .then(response => {
                                         let data = response.data.data
                                         this.defineForm.academicYear = data.academic_year
                                         this.defineForm.module = data.module
                                         this.defineForm.level = data.level
                                         this.defineForm.class = data.class_id
                                         this.defineForm.instructor = data.instructor_id
                                         this.defineForm.type = data.type
                                         this.defineForm.day = data.day
                                         this.defineForm.startTime = data.start_time
                                         this.defineForm.endTime = data.end_time
                                     })
        },

        async sendForm() {
            const isValid = await this.$refs.updateForm.validate();
            if (isValid) {
                let formData = {
                    class_id: this.defineForm.class,
                    instructor_id: this.defineForm.instructor,
                    type: this.defineForm.type,
                    day: this.defineForm.day,
                    start_time: this.defineForm.startTime,
                    end_time: this.defineForm.endTime
                }

                PrepScheduleDraftsService.update(this.id, formData).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$emit('hide')
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') { ///406 geldiği zaman değiştirilecek
                        if (e.data.message) {
                            this.$refs.updateForm.errors.moduleId.push(this.$t('api.' + e.data.message));
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    }
                    else {
                        if (e.data.errors.class_id) {
                            this.$refs.updateForm.errors.class.push(e.data.errors.class_id[0]);
                        }
                        if (e.data.errors.instructor_id) {
                            this.$refs.updateForm.errors.instructor.push(e.data.errors.instructor_id[0]);
                        }
                        if (e.data.errors.type) {
                            this.$refs.updateForm.errors.type.push(e.data.errors.type[0]);
                        }
                        if (e.data.errors.day) {
                            this.$refs.updateForm.errors.day.push(e.data.errors.day[0]);
                        }
                        if (e.data.errors.start_time) {
                            this.$refs.updateForm.errors.startTime.push(e.data.errors.start_time[0]);
                        }
                        if (e.data.errors.end_time) {
                            this.$refs.updateForm.errors.endTime.push(e.data.errors.end_time[0]);
                        }
                    }
                })
            }
        }
    },

    created() {

    },

    watch: {
        id: {
            handler: function (val) {
                this.getFormData(val)
            }
        }
    }
};
</script>
